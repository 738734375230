import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-watch',
  templateUrl: './watch.component.html',
  styleUrls: ['./watch.component.css']
})
export class WatchComponent implements OnInit {

  
  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  
  }

}

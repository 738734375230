
<div class="bckgd">
    <app-watch-desc></app-watch-desc>
    <app-castcard-car></app-castcard-car>
    <app-reviews></app-reviews>

    <app-watchcars [watchcar_name]="'reco'" ></app-watchcars>
    <app-watchcars [watchcar_name]="'similar'"></app-watchcars>
    
    <!-- <footer class="footer">
        <p>
            Powered by TMDB. Developed by Pratik
        </p>
    </footer> -->

</div>